import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_MONETIZATION_AUTH_POPUP_EXP = defineFcm('monetization_auth_popup_exp')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				experiment_name: { type: 'string' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
