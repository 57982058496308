import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_SIGNATURE_GROWTH_PSF_WHATSAPP_SHARE_MESSAGE = defineFcm('signature_growth_psf_whatsapp_share_message')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				isEnabled: { type: 'boolean' },
				experimentName: { type: 'string' },
				variants: {
					type: 'record',
					keys: ['fake_control', 'variant1', 'variant2', 'variant3', 'variant4'] as const,
					shape: { type: 'object', shape: { message: { type: 'string' } } },
				},
			},
		}),
	)
	.withDefault({
		isEnabled: false,
		experimentName: '',
		variants: {
			fake_control: { message: '' },
			variant1: { message: '' },
			variant2: { message: '' },
			variant3: { message: '' },
			variant4: { message: '' },
		},
	})
	.toConfig();
