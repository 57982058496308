import { getLocales } from '@change-corgi/config/locales';
import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_CAPYBARA_IMPACT_STORY = defineFcm('capybara_impact_story')
	.asJson(
		createShapeNormalizer({
			type: 'record',
			keys: getLocales(),
			shape: {
				type: 'object',
				shape: {
					enabled: { type: 'boolean' },
					impactStories: {
						type: 'record',
						shape: {
							type: 'object',
							shape: {
								badgeLabel: { type: 'string' },
								title: { type: 'string' },
								videoUrl: { type: 'string' },
								sapImage: { type: 'string' },
								quoteImage: { type: 'string' },
								starterQuote: { type: 'string' },
								paragraphs: { type: 'array', shape: { type: 'string' } },
							},
						},
					},
				},
			},
		}),
	)
	.withDefault({})
	.toConfig();
