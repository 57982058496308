import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_CAMPAIGN_ACTIONS_PAGE_CONFIG = defineFcm('campaign_actions_page_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				enabled: {
					optional: true,
					type: 'boolean',
				},
				activatedPercentage: {
					optional: true,
					type: 'number',
				},
				specificPetitions: {
					optional: true,
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							id: { type: 'number' },
						},
					},
				},
				blocklist: {
					optional: true,
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							id: { type: 'number' },
						},
					},
				},
				supportedLocales: {
					optional: true,
					type: 'array',
					shape: { type: 'string' },
				},
				isUploadEnabled: {
					optional: true,
					type: 'boolean',
				},
			},
		}),
	)
	.withDefault({
		enabled: false,
		supportedLocales: undefined,
		isUploadEnabled: undefined,
		activatedPercentage: undefined,
		specificPetitions: undefined,
		blocklist: undefined,
	})
	.toConfig();
